/**
 * Address bar container
 */

import { connect } from 'react-redux'

import { toggleAddress } from 'redux/addressModal/actions'

import Component from './component'

const mapStateToProps = (state, props) => {
  return {
    hideOnSelect: props.hideOnSelect,
    loading: state.loading.address,
    location: state.location
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleAddress: () => dispatch(toggleAddress())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
