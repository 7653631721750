
import { withRouter } from 'next/router'
import { connect } from 'react-redux'

import { getCartCount, getCartId } from '@/redux/cart/selectors'
import { toggleAddress } from 'redux/addressModal/actions'
import { getHasHydrated } from 'redux/app/selectors'
import { isLoggedIn } from 'redux/auth/selectors'
import { goToCartDrawer } from 'redux/cart/actions'
import { toggleSidebar } from 'redux/menu/actions'
import { toggleSearchDrawer } from 'redux/search/actions'

import Component from './component'

const mapStateToProps = (state, props) => ({
  cartId: getCartId(state),
  cartCount: getCartCount(state),
  isLoggedIn: isLoggedIn(state),
  hydrated: getHasHydrated(state)
})

const mapDispatchToProps = (dispatch) => ({
  toggleAddress: () => dispatch(toggleAddress()),
  toggleSidebar: () => dispatch(toggleSidebar()),
  toggleSearchDrawer: () => dispatch(toggleSearchDrawer()),
  goToCartDrawer: () => dispatch(goToCartDrawer())
})

const hasRouter = withRouter(Component)

export default connect(mapStateToProps, mapDispatchToProps)(hasRouter)
