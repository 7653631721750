/**
 * This is the address bar that displays the current activeLocation.
 * Tapping should open up the address select modal.
 */

import React, { PureComponent } from 'react'

import styled from '@emotion/styled'

import { DesktopOnly } from 'microcomponents/responsive'

import { bool, func, object, string } from 'prop-types'

import { TEST_IDS } from '../test/constants'

export default class AddressDisplay extends PureComponent {
  static propTypes = {
    color: string,
    loading: bool,
    location: object,
    toggleAddress: func
  }

  render() {
    const { color, loading, location, toggleAddress } = this.props

    const address = location && location.activeLocation
    if (!address) return null

    return (
      <AddressContainer className="fs-mask">
        <AddressBar data-e2eid={TEST_IDS.ADDRESS_PICKER} onClick={(event) => toggleAddress()} style={{ color }}>
          {loading ? (
            'Updating location...'
          ) : (
            <CenterTitle>
              {address.title && !location.inSampleLocation && <DesktopOnly>Delivering to</DesktopOnly>}
              &nbsp;
              {title(address, location.inSampleLocation) + ' ▾'}
            </CenterTitle>
          )}
        </AddressBar>
      </AddressContainer>
    )
  }
}

// Takes in an address object returned by Google and returns the correct address
const CITY_ADDRESS_TYPES = ['locality', 'political']

function title(address, inSampleLocation) {
  if (inSampleLocation) {
    return 'Please enter your address'
  }

  if (!address.city && address.zip) {
    return address.zip
  }

  // if the address has a city type, which means its types array has one or member of the city_address_types,
  // then display the city name
  if (address.types && address.types.some((type) => CITY_ADDRESS_TYPES.includes(type))) {
    return address.city
  } else {
    return address.title ? address.title : 'Please enter your address'
  }
}

const CenterTitle = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 400;
  letter-spacing: 0.03em;
  font-size: 1.2rem;

  & img {
    margin-right: 1rem;
  }
`

const AddressBar = styled.button`
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
